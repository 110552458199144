<template>
<div>
            <div class="mb-2 w-100">
              <b-form-input
                v-model="searchQuery"
                @input="refetchData"
                class="d-inline-block mr-1"
                placeholder="Zoeken..."
              />
              </div>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="getUsers"
        responsive
        :fields="tableColumns"
        :busy="notGroupMembers == null || loading"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Geen gebruikers gevonden"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- A custom formatted header cell for field 'selected' -->
        <template #head(selected)>
          <b-form-checkbox
            @change="selectAll"
            :checked="selected.length === notGroupMembers.length"
            :indeterminate="selected.length < notGroupMembers.length && selected.length > 0"
          ></b-form-checkbox>
        </template>
        <!-- Column: Selected -->
        <template #cell(selected)="data">
          <b-form-checkbox
            name="selected-items"
            v-model="selected"
            :value="data.item.uoid"
          ></b-form-checkbox>
        </template>
        <!-- Column: DisplayName -->
        <template #cell(displayName)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                v-if="
                  data.item.type ||
                  data.item.organisations[$store.state.organisation.id]
                "
                size="32"
                :src="data.item.profilePic ? data.item.profilePic.small : null"
                :text="avatarText(data.item.displayName)"
                :variant="`light-${resolveUserRoleVariant(
                  data.item.type ||
                    data.item.organisations[$store.state.organisation.id].type
                )}`"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.displayName }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>
        <!-- Column: Status -->
        <template #cell(groupIds)="data">
          <div class="d-flex wrap justify-content-start">
            <b-link
              :key="k"
              v-for="(g, k) in data.item.groups"
              :to="{ name: 'group-detail', params: { id: k } }"
            >
              <b-badge
                pill
                :style="`background-color: ${getGroupColor(
                  $store,
                  k
                )}15; color: ${getGroupColor($store, k)}`"
                class="text-capitalize mr-25"
              >
                {{ g.name }}
              </b-badge>
            </b-link>
          </div>
        </template>
      </b-table>
      <div>
          <b-button variant="primary" @click="addUsers" :disabled="selected.length < 1 || loading">{{selected.length}} gebruikers toevoegen aan {{group.name}}</b-button>
      </div>
</div>
  
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BModal,
  BAvatarGroup,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  VBModal,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import mainMixin from "@/mixins/mainMixin";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    mixins: [mainMixin],
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BAvatarGroup,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
    FeatherIcon,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  props: { group: { required: true } },
  data() {
    return {
      searchQuery: "",
      selected: [],
      addUsersDialogVisible: false,
      itemsPerPage: 10,
      currentPage: 1,
      loading: false,
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        { key: "selected", label: "" },
        { key: "displayName", label: "Naam", sortable: true },
        { key: "birthday", label: "Geboortedatum", sortable: true },
        { key: "groupIds", label: "Groepen", sortable: true },
      ],
      sortBy: "displayName",
      isSortDirDesc: false,
      refUserListTable: this.$refs["refUserListTable"],
    };
  },
  watch: {
    $props: {
      handler() {
        this.refetchData();
      },
      deep: true,
      immediate: false,
    },
  },
  computed: {
    totalusers() {
      return this.notGroupMembers.length;
    },
    dataMeta() {
      const localItemsCount =
        this.refUserListTable && this.refUserListTable.value
          ? this.refUserListTable.value.length
          : 0;

      var to = this.itemsPerPage * this.currentPage + localItemsCount;
      if (this.totalusers != 0 && to > this.totalusers) {
        to = this.totalusers;
      }

      return {
        from:
          this.itemsPerPage * (this.currentPage - 1) +
          (localItemsCount ? 1 : 0) +
          1,
        to: to,
        of: this.totalusers,
      };
    },
    notGroupMembers() {
      if (this.$store.state.userManagement) {
        return this.$store.state.userManagement.users.filter(this.filterToAdd);
      } else {
        return [];
      }
    },
  },
  methods: {
    avatarText: avatarText,
    refetchData() {
      this.refUserListTable.refresh();
    },
    getUsers(ctx, callback) {
      if (
        !(
          this.notGroupMembers.length > 0 &&
          this.$store.state.userManagement.users &&
          this.$store.state.userManagement.users.length > 0
        )
      ) {
        this.fetchUsers().then(() => {
          callback(this.filterUsers(this.notGroupMembers));
        });
      } else {
        callback(this.filterUsers(this.notGroupMembers));
      }
    },
    async fetchUsers() {
      return this.$store.dispatch(
        "userManagement/fetchUsers",
        this.$store.state.organisation.id
      );
    },
    filterToAdd(u) {
      if (u.groupIds) {
        return !u.groupIds.includes(this.group.id);
      } else {
        return true;
      }
    },
    filterUsers(users) {
      var sortOrder = 1;
      if(this.isSortDirDesc) sortOrder = -1;
      users.sort((a,b) => ((a[this.sortBy] > b[this.sortBy]) ? 1 : ((b[this.sortBy] > a[this.sortBy]) ? -1 : 0))*sortOrder);
      users = this.searchArray(users, this.searchQuery);
      return users.slice(this.dataMeta.from-1, this.dataMeta.to);
    },
    addUsers() {
        this.loading = true;
    var users = [];
      this.selected.forEach((m) => {
        var i = this.$store.state.userManagement.users.findIndex(item => item.uoid == m);
        users.push(this.$store.state.userManagement.users[i]);
      });
      this.$store
          .dispatch("userManagement/addUsersToGroup", {
            orgId: this.$store.state.organisation.id,
            groupId: this.group.id,
            users: users,
          })
          .then(() => {
            this.selected = [];
            this.refetchData();
            this.$emit('input', users);
            this.$toast({
                component: ToastificationContent,
                props: {
                title: `${users.length} gebruikers toegevoegd aan ${this.group.name}!`,
                icon: "CheckIcon",
                variant: "success",
                },
            });
          }).catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                title: "Er ging iets fout!",
                icon: "AlertCircleIcon",
                variant: "danger",
                },
            });
            console.log(error);
          })

    },
    compareByType(a, b) {
      if (a.groups[this.group.id].type < b.groups[this.group.id].type) {
        return -1;
      }
      if (a.groups[this.group.id].type > b.groups[this.group.id].type) {
        return 1;
      }
      return 0;
    },
    selectAll() {
      if (this.selected.length === this.totalusers) {
        this.selected = [];
      } else {
        this.selected = this.notGroupMembers.map((a) => a.uoid);
      }
    },
  },
  mounted() {
    this.refUserListTable = this.$refs["refUserListTable"];
    this.$watch(
      (vm) => [vm.currentPage, vm.itemsPerPage, vm.sortBy, vm.isSortDirDesc],
      (val) => {
        this.refetchData();
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
  beforeDestroy() {
    // this.unwatch();
  },
}
</script>
