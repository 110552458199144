<template>
  <b-avatar :src="src" :size="radius" class="pull-up" />
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import { storage } from "@/firebase/firebaseConfig";
export default {
  components: {
    BAvatar,
  },
  props: {
    uid: {},
    uoid: {},
    radius: {
        default: 50
    }
  },
  data() {
    return {
      src: null,
    };
  },
  created() {
    this.getUrl();
  },
  methods: {
    async getUrl() {
      var ref;
      if (this.uid != null) {
        ref = storage
          .ref("users")
          .child(this.uid)
          .child(`${this.uid}-${this.getSize()}.jpg`);
      } else if (this.uoid != null) {
        ref = storage
          .ref("organisations")
          .child(this.$store.state.organisation.id)
          .child("users")
          .child(this.uoid)
          .child(`${this.uoid}-${this.getSize()}.jpg`);
      }
      try{
          this.src = await ref.getDownloadURL();
      } catch(error){
          // DO NOTHING
      }
      
      
    },
    getSize() {
      if (this.radius < 61) {
        return "placeholder";
      } else if (this.radius < 201) {
        return "small";
      } else {
        return "medium";
      }
    },
  },
};
</script>

<style>
</style>