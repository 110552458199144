<template>
  <component :is="false ? 'div' : 'b-card'">
   
    <div class="d-flex align-items-center mb-2">
      <feather-icon icon="EyeIcon" size="18" />
      <h4 class="mb-0 ml-75">Groepsfoto</h4>
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <!-- media -->
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-avatar
                ref="previewEl"
                size="100px"
                variant="light-primary"
                :text="groupInitials"
                :src="profilePic"
                :disabled="loading"
              />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              :disabled="loading"
              @click="$refs.refInputEl.$el.click()"
            >
              Aanpassen
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              :disabled="loading"
              @input="inputImageRenderer"
            />
            <!--/ upload button -->
            <b-card-text>JPG of PNG bestanden</b-card-text>
          </b-media-body>
        </b-media>

        <!--/ media -->
        <!-- form -->
        <b-form class="mt-2" @submit.prevent="saveGroup">
          <b-row>
            <b-col sm="12">
              <div class="d-flex align-items-center mb-2 mt-2">
                <feather-icon icon="DropletIcon" size="18" />
                <h4 class="mb-0 ml-75">Kleur</h4>
              </div>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Hoofdkleur" label-for="o-primaryColor">
                <input-color-picker
                  v-model="g.color"
                  :class="$v.g.color.$error ? 'is-invalid' : ''"
                  name="o-primaryColor"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <div class="d-flex align-items-center mb-2 mt-2">
                <feather-icon icon="InfoIcon" size="18" />
                <h4 class="mb-0 ml-75">Algemeen</h4>
              </div>
            </b-col>
            <b-col sm="12">
              <b-form-group label="Groepsnaam" label-for="o-primaryColor">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="g.name"
                  :class="$v.g.name.$error ? 'is-invalid' : ''"
                  placeholder="Groupsnaam"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <div class="d-flex align-items-center mb-2 mt-2">
                <feather-icon icon="CheckIcon" size="18" />
                <h4 class="mb-0 ml-75">Actief</h4>
              </div>
            </b-col>
            <b-col sm="12">
              <b-form-group label-for="g-active">
                <b-form-checkbox
                  v-model="g.active"
                  placeholder="Actief"
                  name="g-active"
                >{{g.active ? 'Actief' : 'Niet actief'}}</b-form-checkbox>
                
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="loading"
                type="submit"
              >
                <b-spinner v-if="loading" small></b-spinner>
                Opslaan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="resetForm"
              >
                Annuleren
              </b-button>
            </b-col>

            <b-col cols="12" class="mt-3">
              <h4 class="text-danger">Danger zone</h4>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                :disabled="loading"
                @click="removeGroup"
              >
                <b-spinner v-if="loading" small></b-spinner>
                Groep verwijderen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div>
        <b-card class="m-0">
          <!-- <div ref="qrcode"></div> -->
          <pdf-generator v-if="verData != null" :data="{ver: verData, g:g}" :type="'group'" ref="pdfGenerator">
          <b-button
            slot="trigger" @click="$refs.pdfGenerator.generatePDF()"
                class="mt-1 w-100"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="loading"
              >
              Download poster
          </b-button>
          </pdf-generator>
        </b-card>
      </div>
    </div>
  </component>
</template>

<script>
import { db, functions } from "@/firebase/firebaseConfig";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BSpinner,
  BAlert,
  BFormFile,
  BMedia,
  VBModal,
  BMediaAside,
  BMediaBody,
  BOverlay,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import InputColorPicker from "vue-native-color-picker";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import PdfGenerator from '@/components/PdfGenerator.vue';

export default {
  directives: {
    "b-modal": VBModal,
  },
  props: {
    group: {
      required: true,
    },
  },
  components: {
    PdfGenerator,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BSpinner,
    BAlert,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BOverlay,
    InputColorPicker,
    PdfGenerator,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      profileFile: null,
      profilePic: this.group.logo ? this.group.logo.medium : null,
      g: this.group,
      code: null,
      verData: null
    };
  },
  created() {
    this.getVerificationCode();
    if(this.g.active == null){
      console.log('here');
      this.g.active = true;
      this.$store.dispatch("updateGroup", {
          group: this.g,
          notify: (arg) => this.$toast(arg),
        });
    }
  },
  methods: {
    async saveGroup() {
      if (!this.$v.$invalid) {
        this.loading = true;
        console.log('here2');
        await this.$store.dispatch("updateGroup", {
          group: this.g,
          notify: (arg) => this.$toast(arg),
        });
        this.loading = false;
      } else {
        this.$v.$touch();
      }
    },
    resetForm() {
      this.g = this.group;
    },

    getVerificationCode() {
      this.qrReady = false;
      this.verData = null;
      functions
        .httpsCallable("getGroupCode")({
          groupId: this.g.id,
          groupName: this.g.name,
          organisationId: this.$store.state.organisation.id,
        })
        .then((res) => {
          if (res.data) {
            this.verData = res.data;
          }
        });
    },
    refreshVerificationCode() {
      this.qrReady = false;
      this.verData = null;
      functions
        .httpsCallable("generateNewGroupCode")({
          groupId: this.g.id,
          groupName: this.g.name,
          organisationId: this.$store.state.organisation.id,
        })
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.verData = res.data;
          }
        });
    },

    inputImageRenderer() {
      const file = this.profileFile;
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.profilePic = reader.result;
          this.uploadFile();
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },
  
    uploadFile() {
      this.loading = true;
      this.$store
        .dispatch("uploadImageToPath", {
          imageName: `/organisations/${
            this.$store.state.organisation.id
          }/groups/${this.g.id}/${this.g.id}.${this.profileFile.name
            .split(".")
            .pop()}`,
          file: this.profileFile,
        })
        .then((result) => {
          this.g.logo = result;
          return this.$store.dispatch("updateGroup", {
            group: this.g,
            notify: (arg) => this.$toast(arg),
          });
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => (this.loading = false));
    },

    removeProfilePic() {
      this.profilePic = null;
      this.profileFile = null;
    },
    removeGroup() {
      this.$bvModal
        .msgBoxConfirm(
          `Je staat op het punt ${this.g.name} te verwijderen. Dit kan niet ongedaan gemaakt worden.`,
          {
            title: "Ben je zeker?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Verwijderen",
            cancelTitle: "Annuleren",
            cancelVariant: "outline-secondary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
         
          if (value) {
             this.loading = true;
            db.collection("organisations")
              .doc(this.$store.state.organisation.id)
              .collection("groups")
              .doc(this.g.id)
              .delete()
              .then(() => {
                this.loading = true;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Groep verwijderd!",
                    icon: "CheckIcon",
                    variant: "danger",
                  },
                });
                this.$store.dispatch(
                  "loadGroups",
                  this.$store.state.organisation.id
                );
                this.$router.push("/");
              })
              .catch((error) => {
                this.loading = false;
                console.log(error);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error!",
                    text: error.message,
                    icon: "CheckIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
    },
  },
  computed: {
    groupInitials() {
      let name = this.g.name;
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();

      return initials;
    },
  },
  validations: {
    g: {
      color: {},
      name: {
        required,
      },
    },
  },
};
</script>

<style>
</style>