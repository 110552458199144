var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-2 w-100"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Zoeken..."},on:{"input":_vm.refetchData},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.getUsers,"responsive":"","fields":_vm.tableColumns,"busy":_vm.notGroupMembers == null || _vm.loading,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"Geen gebruikers gevonden","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('b-form-checkbox',{attrs:{"checked":_vm.selected.length === _vm.notGroupMembers.length,"indeterminate":_vm.selected.length < _vm.notGroupMembers.length && _vm.selected.length > 0},on:{"change":_vm.selectAll}})]},proxy:true},{key:"cell(selected)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"name":"selected-items","value":data.item.uoid},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}},{key:"cell(displayName)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [(
                  data.item.type ||
                  data.item.organisations[_vm.$store.state.organisation.id]
                )?_c('b-avatar',{attrs:{"size":"32","src":data.item.profilePic ? data.item.profilePic.small : null,"text":_vm.avatarText(data.item.displayName),"variant":("light-" + (_vm.resolveUserRoleVariant(
                  data.item.type ||
                    data.item.organisations[_vm.$store.state.organisation.id].type
                )))}}):_vm._e()]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(data.item.displayName)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.email))])],1)]}},{key:"cell(groupIds)",fn:function(data){return [_c('div',{staticClass:"d-flex wrap justify-content-start"},_vm._l((data.item.groups),function(g,k){return _c('b-link',{key:k,attrs:{"to":{ name: 'group-detail', params: { id: k } }}},[_c('b-badge',{staticClass:"text-capitalize mr-25",style:(("background-color: " + (_vm.getGroupColor(
                  _vm.$store,
                  k
                )) + "15; color: " + (_vm.getGroupColor(_vm.$store, k)))),attrs:{"pill":""}},[_vm._v(" "+_vm._s(g.name)+" ")])],1)}),1)]}}])}),_c('div',[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.selected.length < 1 || _vm.loading},on:{"click":_vm.addUsers}},[_vm._v(_vm._s(_vm.selected.length)+" gebruikers toevoegen aan "+_vm._s(_vm.group.name))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }