<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-25"> Evolutie </b-card-title>
      </div>
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        :options="salesLine.chartOptions"
        :series="salesLine.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Vue from "vue";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
  },
  props: {
    stats: {
      required: true,
    },
  },
  computed: {
    salesLine() {
      return {
        series: [
          {
            name: "Aanwezig",
            data: this.stats.pRates,
          },
          {
            name: "Te laat",
            data: this.stats.lRates || [],
          },
          {
            name: "Afwezig",
            data: this.stats.nRates || [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: [
            $themeColors.success,
            $themeColors.warning,
            $themeColors.danger,
          ],
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: this.stats.categories,
            axisBorder: {
              show: false,
            },
            tickPlacement: "between",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#e6277a",
                fontSize: "0.857rem",
              },
              formatter(val) {
                return `${val}%`;
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      };
    },
  },
  data() {
    return {};
  },
};
</script>
