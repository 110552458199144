<template>
  <!-- User Info: Input Fields -->
  <b-overlay :show="loading">
    <b-form @submit.prevent="submit">
      <b-row>
        <!-- Field: name -->
        <b-col cols="6" md="6">
          <b-form-group label="Naam (optioneel)" label-for="name">
            <b-form-input
              id="name"
              :class="$v.newPresence.name.$error ? 'is-invalid' : ''"
              v-model="newPresence.name"
              placeholder="Naam (optioneel)"
            />
          </b-form-group>
          <b-form-group label="Tijdstip" label-for="time">
            <flat-pickr
              class="form-control"
              id="v-start"
              @on-change="loadNearbyEvents()"
              :config="configdateTimePicker"
              v-model="newPresence.time"
              placeholder="Tijdstip"
              :class="$v.newPresence.time.$error ? 'is-invalid' : ''"
            />
          </b-form-group>
          <b-form-group label="Activiteiten" label-for="time" v-if="activities.length > 0 && !newPresence.eventId">
            <b-list-group>
              <b-list-group-item :key="a.id"  v-for="a in activities" class="d-flex justify-content-between align-items-center">
                <span>{{`${a.name} (${formatDate(a.start, 'dd D/MM - HH:mm')})`}}</span>
                <b-button @click="linkEvent(a)" size="sm" variant="outline-primary">Koppelen</b-button>
              </b-list-group-item>
            </b-list-group>
          </b-form-group>
           <b-form-group label="Gelinkt aan activiteit" label-for="time" v-else-if="linkedActivity">
            <b-list-group>
              <b-list-group-item class="d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <span class="mr-1">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
      </span>
                <span>{{`${linkedActivity.name} (${formatDate(linkedActivity.start, 'dd D/MM - HH:mm')})`}}</span>
                </div>
                
              </b-list-group-item>
            </b-list-group>
          </b-form-group>
        </b-col>
        <!-- Field: date -->
        <b-col cols="6" md="6">
          <label>Aanwezigheden</label>
          <b-list-group>
            <b-list-group-item
              :key="u.uoid"
              v-for="u in groupMembers"
              class="d-flex justify-content-between align-items-center"
            >
              <span>{{ u.displayName }}</span>
              <b-dropdown
                :id="'dropdown-grouped-' + u.uoid"
                :variant="colorMap[newPresence.entries[u.uoid] || 'u']"
                left
                lazy
                v-b-tooltip.hover.top="
                  codeMap[newPresence.entries[u.uoid] || 'u']
                "
                class="dropdown-icon-wrapper presence-dropdown"
              >
                <template #button-content>
                  <feather-icon
                    :icon="iconMap[newPresence.entries[u.uoid] || 'u']"
                    size="16"
                    class="align-middle"
                  />
                </template>
                <b-dropdown-item
                  :variant="colorMap[i || 'u']"
                  :key="i"
                  v-for="i in Object.keys(codeMap)"
                  @click="changeNewPresence(u.uoid, i)"
                >
                  <feather-icon :icon="iconMap[i]" size="18" class="mr-1" />
                  <span>{{ codeMap[i] }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-list-group-item></b-list-group
          >
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end mt-2">
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="cancelForm"
            >
              Annuleren
            </b-button>
            <b-button variant="primary" type="submit"> Opslaan </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BOverlay,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { db, getTimestamp } from "@/firebase/firebaseConfig";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import XLSX from "xlsx";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import Vue from "vue";

import mainMixin from '@/mixins/mainMixin';

export default {
  mixins: [mainMixin],
  data() {
    return {
      newPresence: null,
      loading: false,
      activities: [],
      linkedActivity: null,
      configdateTimePicker: {
        enableTime: true,
        altInput: true,
        altFormat: "d/m/Y -  H:i",
        time_24hr: true,
        locale: Dutch,
      },
      iconMap: {
        u: "HelpCircleIcon",
        p: "CheckIcon",
        l: "ClockIcon",
        "np-p": "XIcon",
        "np-np": "XIcon",
      },
      colorMap: {
        u: "primary",
        p: "success",
        l: "warning",
        "np-p": "outline-danger",
        "np-np": "danger",
      },
      codeMap: {
        u: "Onbekend",
        p: "Aanwezig",
        l: "Te laat",
        "np-p": "Niet aanwezig (verontschuldigd)",
        "np-np": "Niet aanwezig",
      },
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BButton,
    BOverlay,
    FeatherIcon,
    BCard,
    flatPickr,
    BForm,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
  },
  props: {
    presence: {
      type: Object,
      required: true,
    },
    group: {
      required: true,
    },
  },
  watch: {
    $props: {
      handler() {
        this.setData();
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    submit() {
      this.loading = true;
      if (!this.newPresence.name || this.newPresence == "") {
        this.newPresence.name = moment(this.newPresence.time).format(
          'dddd D MMMM [om] h:mm"'
        );
      }
      this.newPresence.time = moment(this.newPresence.time).toDate();
      var ref = db
        .collection("organisations")
        .doc(this.$store.state.organisation.id)
        .collection("presences");
      if (this.newPresence.id) {
        ref = ref.doc(this.newPresence.id).update(this.newPresence);
      } else {
        ref = ref.add(this.newPresence);
      }

      ref
        .then((res) => {
          var batch = db.batch();
          Object.keys(this.newPresence.entries).forEach((k) => {
            batch.set(
              db
                .collection("organisations")
                .doc(this.$store.state.organisation.id)
                .collection("presences")
                .doc(res ? res.id : this.newPresence.id)
                .collection('entries')
                .doc(k),
              {
                groupIds: [this.group.id],
                lastUpdate: getTimestamp(),
                presence: this.newPresence.entries[k],
              },
              { merge: true }
            );
          });
          return batch.commit();
        })
        .then(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Wijzigingen opgeslaan!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit("close");
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Er ging iets fout!",
              text: error.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    setData() {
      this.newPresence = { ...this.presence, ...{} };
    },
    linkEvent(a){
      this.newPresence.eventId = a.id;
      this.newPresence.name = a.name;
      this.newPresence.time = a.start;
      this.linkedActivity = a;
    },
    cancelForm() {
      this.$emit("close");
    },
    changeNewPresence(uoid, k) {
      var e = { ...this.newPresence.entries, ...{} };
      Vue.set(e, uoid, k);
      this.newPresence.entries = { ...e, ...{} };
    },
    async loadLinkedActivity(){
      var snap = await db.collection('organisations').doc(this.$store.state.organisation.id).collection('events').doc(this.newPresence.eventId).get();
      this.linkedActivity = {id: snap.id, ...snap.data()}

    },
    loadNearbyEvents(){
      const time = moment(this.newPresence.time).toDate();
      const rangeStart = time.addHours(12);
      const rangeEnd = time.substractHours(12);
      console.log(rangeStart, rangeEnd);
       this.$store
            .dispatch("calendar/fetchEventsForRange", {
              orgId: this.$store.state.organisation.id,
              start: rangeStart,
              end: rangeEnd,
              groupIds: this.newPresence.groupIds
            })
            .then((res) => {
              this.activities = res.activities;
            });
    },
    filterUsers(u) {
      if (u.groupIds) {
        return u.groupIds.includes(this.group.id);
      } else {
        return false;
      }
    },
    compareByType(a, b) {
      if (a.groups[this.group.id].type < b.groups[this.group.id].type) {
        return -1;
      }
      if (a.groups[this.group.id].type > b.groups[this.group.id].type) {
        return 1;
      }
      return 0;
    },
  },
  created() {
    this.setData();
    if(this.newPresence.eventId){
      this.loadLinkedActivity();
    } else if(this.newPresence.id) {
      this.loadNearbyEvents();
    }
  },
  computed: {
    groupMembers() {
      if (this.$store.state.userManagement) {
        return this.$store.state.userManagement.users
          .filter(this.filterUsers)
          .sort(this.compareByType);
      } else {
        return [];
      }
    },
  },
  validations: {
    newPresence: {
      name: {},
      time: {
        required,
      },
    },
  },
};
Date.prototype.addHours= function(h){
    this.setHours(this.getHours()+h);
    return this;
}
Date.prototype.substractHours= function(h){
    this.setHours(this.getHours()-h);
    return this;
}
</script>

<style>
</style>