<template>
  <component :is="true ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="group === undefined">
      <h4 class="alert-heading">Error</h4>
      <div class="alert-body">
        Geen groep gevonden met dit id, ga terug naar het
        <b-link class="alert-link" :to="{ name: 'groups' }"> overzicht </b-link>
        voor andere groepen.
      </div>
    </b-alert>
    <div v-if="group">
      <b-tabs >
        <!-- Tab: Lijst -->
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Gebruikers</span>
          </template>
          <group-tab-list :group="group"/>
        </b-tab>

         <!-- Tab: Nieuws -->
        <b-tab lazy v-if="isModuleEnabled('posts')">
          <template #title>
            <feather-icon icon="HomeIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Nieuws</span>
          </template>
          <group-feed :group="group.id"/>
        </b-tab>

        <!-- Tab: Aanwezigheden -->
        <b-tab lazy v-if="isModuleEnabled('presences')">
          <template #title>
            <feather-icon
              icon="UserCheckIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Aanwezigheden</span>
          </template>
          <group-tab-presences :group="group" />
        </b-tab>
        <!-- Tab: Kalender -->
        <b-tab lazy v-if="isModuleEnabled('calendar')">
          <template #title>
            <feather-icon icon="CalendarIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Kalender</span>
          </template>
          <calendar :config="calendarConfig"/>
        </b-tab>
        <!-- Tab: Instellingen -->
        <b-tab lazy>
          <template #title>
            <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Instellingen</span>
          </template>
          <group-edit :group="group" />
        </b-tab>
      </b-tabs>
      
    </div>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import GroupTabList from "./GroupTabList";
import GroupFeed from "./GroupFeed";
import GroupTabPresences from "./presences/GroupTabPresences.vue";
import mainMixin from "@/mixins/mainMixin";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import Calendar from '@/views/calendar/Calendar.vue';

import GroupEdit from './GroupEdit';

export default {
  mixins: [mainMixin],
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    GroupTabList,
    GroupFeed,
    GroupEdit,
    GroupTabPresences,
    Calendar,
    FeatherIcon,
    Calendar,
  },

  data() {
    return {
      group: null,
      calendarConfig: {
        showSideBar: false,
        showViewPicker: true,
        showCalNav: true,
        showTodayBtn: true,
        groupIds: [this.$route.params.id]
      }
    };
  },

  watch: {
    '$route.params.id': function (id) {
      this.fetchGroup();
    }
  },

  created() {
    this.fetchGroup();
  },

  methods: {
    fetchGroup() {
      this.$store
        .dispatch("fetchGroup", {
          orgId: this.$store.state.organisation.id,
          groupId: this.$route.params.id,
        })
        .then((res) => {
          this.group = res;
          this.$store.commit('SET_PAGE_TITLE', this.group.name);
        })
        .catch((error) => {
          console.log(error);
          this.group = undefined;
        });
    },
  },
};
</script>

<style>
</style>
