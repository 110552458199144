<template>
  <div style="max-width: 800px; margin: 0 auto">
      <post :key="p.id" v-for="p in posts" :data="p" />
  </div>
</template>

<script>
import mainMixin from '@/mixins/mainMixin';
import Post from './Post';
import {
  BTbody,
  BTable,
  BTh,
  BThead,
  BTableSimple,
  BTr,
  BTd,
  BButton,
  VBTooltip,
  BOverlay,
  BModal,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BCardFooter,
  BCardText,
  BCardGroup,
  VBModal,
} from "bootstrap-vue";
import { db, getTimestamp } from "@/firebase/firebaseConfig";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
mixins: [mainMixin],
  components: {
      Post,
    BCardHeader,
    BCardFooter,
    BCardText,
    BCardGroup,
    BTbody,
    BTable,
    BTh,
    BThead,
    BTableSimple,
    BTr,
    BTd,
    BButton,
    BOverlay,
    BModal,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
  },
  props: {
    group: {
      default: null,
    },
  },
  data() {
    return {
      lastDocument: null,
      loading: false,
      posts: [],
    };
  },
  created() {
    this.loadFeed();
  },
  methods: {
    async loadFeed() {
      
      if (this.group) {
        this.loading = true;
      var query = db.collection("organisations").doc(this.$store.state.organisation.id).collection('groups').doc(this.group).collection('posts');
     
      
      query = query.orderBy("created", "desc").limit(5);
      if (this.lastDocument != null) {
        query = query.startAfter(this.lastDocument);
      }

      var snap = await query.get();

      snap.docs.forEach((doc) => {
        this.posts.push({ ...doc.data(), ...doc.id, ref: doc.ref });
      });
      this.loading = false;
    }
    },
  },
};
</script>

<style>
</style>