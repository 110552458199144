<template>
  <div>
    <!-- Table Container Card -->

    <div class="d-flex justify-content-between my-2">
      <div>
        <h2 v-if="presences.length > 0">
          {{ formatDate(presences[presences.length - 1].time, "D MMMM YYYY") }}
          - {{ formatDate(presences[0].time, "D MMMM YYYY") }}
        </h2>
      </div>
      <div class="d-flex">
        <b-button class="mr-1" size="sm" variant="primary" @click="openModal">
          <feather-icon icon="PlusIcon" />
          Nieuwe aanwezigheden ingeven
        </b-button>
        <b-button size="sm" variant="primary" @click="exportToExcel">
          <feather-icon icon="DownloadCloudIcon" />
          Exporteren naar Excel (.xlsx)
        </b-button>
      </div>
    </div>
    <b-row>
      <b-col cols="6" lg="3">
        <main-precentage
          :percentage="stats.totalPerc"
          title="Gemiddelde aanwezigheid"
        />
      </b-col>
      <b-col cols="6" lg="9">
        <graph-over-time :stats="stats" />
      </b-col>
    </b-row>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading">
        <b-table-simple id="presence-table" responsive>
          <b-thead head-variant="primary">
            <b-tr>
              <b-th sort-key="displayName">Naam</b-th>
              <b-th :key="k" v-for="(p, k) in presences">
                {{ formatDate(p.time) }}
                <b-dropdown
                  variant="flat-secondary"
                  left
                  lazy
                  no-caret
                  size="sm"
                  class="p-0"
                  v-b-tooltip.hover.top="'Acties'"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreHorizontalIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                   <b-dropdown-item @click="editPresence(p)">
                    <feather-icon icon="EditIcon" size="18" class="mr-1" />
                    <span>Bewerken</span>
                  </b-dropdown-item>
                  <b-dropdown-item variant="danger" @click="removePresence(p)">
                    <feather-icon icon="TrashIcon" size="18" class="mr-1" />
                    <span>Verwijderen</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in groupMembers">
              <b-th>{{ tr.displayName }}</b-th>
              <b-td :key="k" v-for="(p, k) in presences">
                <b-dropdown
                  id="dropdown-grouped"
                  :variant="colorMap[p.entries[tr.uoid] || 'u']"
                  left
                  lazy
                  v-b-tooltip.hover.top="codeMap[p.entries[tr.uoid] || 'u']"
                  class="dropdown-icon-wrapper presence-dropdown"
                >
                  <template #button-content>
                    <feather-icon
                      :icon="iconMap[p.entries[tr.uoid] || 'u']"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                  <b-dropdown-item
                    :variant="colorMap[i || 'u']"
                    :key="i"
                    v-for="i in Object.keys(codeMap)"
                    @click="changePresence(k, p, tr.uoid, i)"
                  >
                    <feather-icon :icon="iconMap[i]" size="18" class="mr-1" />
                    <span>{{ codeMap[i] }}</span>
                  </b-dropdown-item>
                </b-dropdown>
                <p class="hidden">{{ codeMap[p.entries[tr.uoid] || "u"] }}</p>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </b-card>
    <b-modal
      ref="modal-add-presence"
      size="lg"
      v-model="newPresenceDialogVisible"
      id="modal-presence"
      hide-footer
      :title="`Aanwezigheden registreren`"
    >
      <group-tab-modal-new-presence
        v-if="newPresenceDialogVisible"
        @close="closeModal"
        :group="group"
        :presence="newPresence"
      />
      <template #modal-footer><span></span></template>
    </b-modal>
  </div>
</template>

<script>
import {
  BTbody,
  BTable,
  BTh,
  BThead,
  BTableSimple,
  BTr,
  BTd,
  BButton,
  VBTooltip,
  BOverlay,
  BModal,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  VBModal,
} from "bootstrap-vue";
import { db, getTimestamp } from "@/firebase/firebaseConfig";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import XLSX from "xlsx";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

import GroupTabModalNewPresence from "./GroupTabModalNewPresence";
import Vue from "vue";

import MainPrecentage from "@/components/MainPrecentage.vue";
import GraphOverTime from "./GraphOverTime.vue";

export default {
  components: {
    BTbody,
    BTable,
    BTh,
    BThead,
    BTableSimple,
    BTr,
    BTd,
    BButton,
    BOverlay,
    FeatherIcon,
    BModal,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    GroupTabModalNewPresence,
    MainPrecentage,
    GraphOverTime,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
  },
  props: {
    group: {
      required: true,
    },
  },
  data() {
    return {
      newPresenceDialogVisible: false,
      newPresence: {},
      loading: false,
      presences: [],
      popupActive: false,
      loading: true,
      selectedP: {},
      stats: {
        totalPerc: 0,
        pRates: [],
        lRates: [],
        nRates: [],
        categories: [],
      },
      iconMap: {
        u: "HelpCircleIcon",
        p: "CheckIcon",
        l: "ClockIcon",
        "np-p": "XIcon",
        "np-np": "XIcon",
      },
      colorMap: {
        u: "primary",
        p: "success",
        l: "warning",
        "np-p": "outline-danger",
        "np-np": "danger",
      },
      codeMap: {
        u: "Onbekend",
        p: "Aanwezig",
        l: "Te laat",
        "np-p": "Niet aanwezig (verontschuldigd)",
        "np-np": "Niet aanwezig",
      },
    };
  },
  created() {
    this.loadPresences();
  },
  methods: {
    loadPresences() {
      db.collection("organisations")
        .doc(this.$store.state.organisation.id)
        .collection("presences")
        .where("groupIds", "array-contains", this.group.id)
        .orderBy("time", "desc")
        .limit(20)
        .get()
        .then((snap) => {
          this.presences = [];
          snap.forEach((doc) => {
            this.presences.push({ id: doc.id, ...doc.data(), ...{time: new Date(doc.data().time.seconds*1000)}});
          });
          this.loading = false;
          this.calculatePresenceRate();
        });
    },
    removePresence(p){
      this.$bvModal
        .msgBoxConfirm(
          `Ben je zeker dat je deze aanwezigheden wil verwijderen? Dit kan niet ongedaan gemaakt worden.`,
          {
            title: "Ben je zeker?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Verwijderen",
            cancelTitle: "Annuleren",
            cancelVariant: "outline-secondary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
             db.collection("organisations")
              .doc(this.$store.state.organisation.id)
              .collection("presences")
              .doc(p.id).delete().then(() => {
                this.loadPresences();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Wijzigingen opgeslaan!",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              })
          }
        });
    },
    editPresence(p){
       this.newPresence = p;
       this.newPresenceDialogVisible = true;
    },
    closeModal() {
      this.loadPresences();
      this.newPresenceDialogVisible = false;
    },
    openModal() {
      this.newPresence = {
        entries: {},
        eventId: null,
        groupIds: [this.group.id],
        lastEdit: getTimestamp(),
        name: "",
        orgId: this.$store.state.organisation.id,
        time: new Date(),
      };
      this.groupMembers.forEach((g) => {
        this.newPresence.entries[g.uoid] = "u";
      });
      this.newPresenceDialogVisible = true;
    },
    getGroup(id) {
      var i = this.$store.state.groups.findIndex((x) => x.id == id);
      return this.$store.state.groups[i];
    },
    formatDate(date, format = "dd D/MM/YY (HH:mm)") {
      return moment(date).format(format);
    },
    getUser(uoid) {
      var i = this.$store.state.userManagement.users.findIndex(
        (x) => x.uoid == uoid
      );
      return this.$store.state.userManagement.users[i];
    },
    calculatePresenceRate() {
      const now = Math.floor(Date.now() / 1000);
      this.stats.pRates = [];
      this.stats.lRates = [];
      this.stats.nRates = [];
      var cats = [];
      var totalSum = 0;
      var count = 0;
      this.presences.forEach((p, i) => {
        if(Math.floor(p.time / 1000) < now){
          count++;
          var sum = 0;
          var nsum = 0;
          var lsum = 0;
          this.groupMembers.forEach((m) => {
            if (p.entries[m.uoid] == "p" || p.entries[m.uoid] == "l") {
              sum++;
              totalSum++;
              if (p.entries[m.uoid] == "l") {
                lsum++;
              }
            } else {
              nsum++;
            }
          });
          this.stats.pRates.push(
            Math.round((sum / this.groupMembers.length) * 100 * 100) / 100
          );
          this.stats.lRates.push(
            Math.round((lsum / this.groupMembers.length) * 100 * 100) / 100
          );
          this.stats.nRates.push(
            Math.round((nsum / this.groupMembers.length) * 100 * 100) / 100
          );
          cats.push(this.formatDate(p.time, "DD/MM"));
        }
        
      });
      this.stats.categories = cats;
      this.stats.totalPerc =
        Math.round(
          (totalSum / (this.groupMembers.length * count)) *
            100 *
            100
        ) / 100;
    },
    changePresence(i, p, uoid, c) {
      var newPresence = {
        groupIds: p.groupIds,
        lastUpdate: getTimestamp(),
        presence: c,
      };

      Vue.set(this.presences[i].entries, uoid, newPresence.presence);

      db.collection("organisations")
        .doc(this.$store.state.organisation.id)
        .collection("presences")
        .doc(p.id)
        .collection("entries")
        .doc(uoid)
        .set(newPresence, { merge: true })
        .then(() => {
          this.calculatePresenceRate();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Wijzigingen opgeslaan!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Er ging iets fout!",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          console.log(error);
        });
      return;
    },

    exportToExcel() {
      var now = new Date();
      var group = this.getGroup(this.group.id);
      var wb = XLSX.utils.table_to_book(
        document.getElementById("presence-table")
      );
      wb.Props = {
        Title: `${group.name} - Aanwezigheden`,
        Subject: `Geexporteerd op ${this.formatDate(Date())}`,
        Author: this.$store.state.organisation.fullName,
        CreatedDate: new Date(),
      };
      XLSX.writeFile(
        wb,
        `${now.getFullYear()}${now.getMonth()}${now.getDate()}-${
          group.name
        }-aanwezigheden.xlsx`
      );
    },
    filterUsers(u) {
      if (u.groupIds) {
        return u.groupIds.includes(this.group.id);
      } else {
        return false;
      }
    },
    compareByType(a, b) {
      if (a.groups[this.group.id].type < b.groups[this.group.id].type) {
        return -1;
      }
      if (a.groups[this.group.id].type > b.groups[this.group.id].type) {
        return 1;
      }
      return 0;
    },
  },
  computed: {
    groupMembers() {
      if (this.$store.state.userManagement) {
        return this.$store.state.userManagement.users
          .filter(this.filterUsers)
          .sort(this.compareByType);
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss">
.dropdown-icon-wrapper {
  .dropdown-toggle {
    border-radius: 50% !important;
    padding: 10px !important;
  }
}
</style>