<template>
    <b-card
    >
      <div
        class="d-flex justify-content-start align-items-center mb-1"
      >
        <!-- avatar -->
        <firebase-avatar
          :uid="data.author.uid"
          size="50"
          class="mr-1"
        />
        <!--/ avatar -->
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ data.author.displayName }} in {{data.targetName}}
          </h6>
          <small class="text-muted">{{ formatDate(data.created) }}</small>
        </div>
      </div>
      <b-card-text>
        {{ data.text }}
      </b-card-text>

      <!-- post img -->
       <b-carousel
       width="100%"
       style="max-height: 500px"
       v-if="data.gallery && data.gallery.length > 0"
      :id="'carousel-'+data.id"
      controls
      indicators
    >
      <b-carousel-slide style="max-height: 500px" :key="k" v-for="(g, k) in data.gallery" :img-src="g.medium" />
    </b-carousel>

      <!--/ post img -->
      <!-- post video -->
      <b-embed
        v-if="data.postVid"
        type="iframe"
        :src="data.postVid"
        allowfullscreen
        class="rounded mb-50"
      />
      <!--/ post video -->

      <!-- likes comments  share-->
      <b-row class="pb-50 mt-50">
        <b-col
          sm="8"
          class="d-flex justify-content-between justify-content-sm-start mb-2"
        >
          <b-link class="d-flex align-items-center text-muted text-nowrap" @click="like">
            <feather-icon
              icon="HeartIcon"
              class="mr-50"
              :class="data.reactions[this.$store.state.AppActiveUser.uid] != null ? 'text-danger':''"
              size="18"
            />
            <span>{{ data.likeCount ? data.likeCount : 0 }}</span>
          </b-link>
          <div class="d-flex align-item-center">
            <b-avatar-group
              size="26"
              class="ml-1"
            >
              <firebase-avatar
                v-for="(reaction,i) in data.reactions"
                :key="i"
                :uid="i"
              />
            </b-avatar-group>
          </div>
        </b-col>
        <b-col
          sm="4"
          class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
        >
          <b-link class="text-body text-nowrap">
            <feather-icon
              icon="MessageSquareIcon"
              size="18"
              class="profile-icon mr-50"
            />
            <span class="text-muted mr-1">{{ data.commentCount }}</span>
          </b-link>
          
        </b-col>
      </b-row>
      <!--/ likes comments  share-->

      <!-- comments -->
      <div
        v-for="(comment,id) in comments"
        :key="id"
        class="d-flex align-items-start mb-1"
      >
        <firebase-avatar
          :uid="comment.author.uid"
          :radius="34"
          class="mt-25 mr-75"
        />
        <div class="profile-user-info w-100">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">
              {{ comment.author.displayName }}
            </h6>
           
          </div>
          <small>{{ comment.text }}</small>
        </div>
      </div>
      <!--/ comments -->

      <!-- comment box -->
      <b-form-group>
        <b-form-textarea
          rows="1"
          placeholder="Reageren..."
          v-model="newComment"
        />
      </b-form-group>
      <!--/ comment box -->

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="primary"
        @click="submitComment"
      >
        Reactie plaatsen
      </b-button>
    </b-card>
</template>

<script>
import Vue from 'vue';
import mainMixin from '@/mixins/mainMixin';
import {
  BAvatar, BCard, BCardText, BImg, BLink, BRow, BCol, BAvatarGroup, VBTooltip, BFormTextarea, BButton, BFormGroup, BEmbed, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { db, getTimestamp } from "@/firebase/firebaseConfig";
import FirebaseAvatar from '@/components/FirebaseAvatar';

export default {
  mixins: [mainMixin],
  components: {
    FirebaseAvatar,
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    BCarousel, 
    BCarouselSlide,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data(){
    return{
      comments: [],
      newComment: ""
    }
  },
  created(){
    this.loadComments();
  },
  methods: {
    async submitComment(){
      var obj = {'text': this.newComment,
      'created': getTimestamp(),
      'lastEdit': getTimestamp(),
      'deleted': false,
      'author': {
        'uid': this.$store.state.AppActiveUser.uid,
        'displayName':  this.$store.state.AppActiveUser.displayName
      }};
      await this.data.ref.collection('comments').add(obj);
      this.newComment = "";
      this.loadComments();
    },
    async loadComments(){
      var snap = await this.data.ref.collection('comments').get();
      this.comments = [];
      snap.docs.forEach(doc => {
        this.comments.push(doc.data());
      });;
      
    },
    like(){
      if(this.data.reactions[this.$store.state.AppActiveUser.uid]){
        this.data.ref.collection('reactions').doc(this.$store.state.AppActiveUser.uid).delete().then(() => {
          Vue.delete(this.data.reactions, this.$store.state.AppActiveUser.uid)
        })
      } else {
        this.data.ref.collection('reactions').doc(this.$store.state.AppActiveUser.uid).set({reaction: 'like'}).then(() => {
          Vue.set(this.data.reactions, this.$store.state.AppActiveUser.uid, 'like')
        })
      }
    }
  }
}
</script>
